<template>
    <path d="m143.1 199.5-136.1-135.9c-9.3-9.4-9.3-24.6 0-33.9l22.6-22.7c9.4-9.3 24.6-9.3 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.7 0z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 320, height: 207 });
    },
    emits: ['dimensions'],
};
</script>
